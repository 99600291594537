<template>
  <div class="w-full h-full pt-5">
    <div class="flex mb-10 px-5">
      <h1 class="text-xl text-left font-extrabold mr-4">Terminal Pay</h1>
      <breadcrumb :items="breadcrumbs" />
    </div>
    <div class="px-3">
      <card class="p-5 mt-3">
        <div class="mx-2 mt-6">
          <Tab
            :tabs="tabs"
            border
            :active-tab="currentTab"
            @currentTab="currentTab = $event"
            v-model="currentTab"
          />
          <div>
            <div>
              <Card class="mt-6 p-5">
                <div>
                  <div class="flex mb-6" style="justify-content: flex-end">
                    <Button
                      class="text-white"
                      :background="appearance.buttonColor || defaultBTNColor"
                      @click="processPayRun()"
                      v-if="currentTab === 'Terminated Employees'"
                      :disabled="selectedEmployees.length === 0"
                    >
                      Process Pay
                    </Button>
                    <Button
                      class="bg-white ml-4 text-dynamicBackBtn border border-solid border-dynamicBackBtn"
                      style-name="width: 180px"
                      options
                      :width="`auto`"
                      :disabled="false"
                      :option-list="[
                        'Back Pay',
                        'Regular Pay Run',
                      ]"
                      @click="buttonOptionSelected($event)"
                    >
                      Pay Options
                    </Button>
                  </div>
                </div>
                <template v-slot:footer>
                  <card-footer v-if="!showError" :show-bulk-actions="false" />
                </template>
              </Card>
            </div>

            <div class="pt-3" v-if="currentTab === 'Terminated Employees'">
              <sTable
                :headers="terminalPayHeaders"
                :items="payrunItems"
                style="width:100%; height:auto"
                class="w-full"
                aria-label="payrun table"
                :loading="loading"
                :has-checkbox="true"
                :has-number="true"
                @rowSelected="processEmployees"
                v-if="payrunItems.length > 0 || loading"
              >
                <template v-slot:item="{ item }">
                  <div v-if="item.photo" class="flex items-center gap-3">
                    <img
                      class=""
                      :src="item.data.photo"
                      alt="photo"
                      v-if="item.data.photo"
                      style="height: 30px; width: 30px; border-radius: 5px"
                    />
                    <div
                      style="height: 35px; width: 35px; border-radius: 5px"
                      class="text-blueCrayola border text-center font-semibold pt-2"
                      v-else
                    >
                      {{ $getInitials(`${item.data.employeeName}`) }}
                    </div>
                  </div>
                  <div
                    class="tw-font-bold flex flex-col"
                    style="color: #525252"
                    v-else-if="item.employeeName"
                  >
                    {{ item.data.employeeName }}
                  </div>
                  <div
                    class="tw-font-bold flex flex-col"
                    style="color: #525252"
                    v-else-if="item.terminalDate"
                  >
                    {{
                      $DATEFORMAT(
                        new Date(item.data.terminalDate),
                        "MMMM dd, yyyy"
                      )
                    }}
                  </div>
                  <span v-else-if="item.id">
                    <Menu left top="-130" class="p-0" margin="40">
                      <template v-slot:title>
                        <icon icon-name="more_icon" size="xs" />
                      </template>
                      <div
                        style="width: 158px; min-height: 40px"
                        class="py-3 px-2"
                      >
                        <div
                          class="flex py-1 px-3 more h-8 cursor-pointer"
                          @click="handleClick('additions', item.data.id)"
                        >
                          <icon
                            icon-name="undo"
                            class-name="text-mediumSeaGreen mr-2"
                            size="xms"
                          />
                          <p class="pt-1" style="font-size: 14px">
                            Outstandings
                          </p>
                        </div>

                        <div
                          class="flex py-2 px-3 more h-8 cursor-pointer"
                          @click="processOneEmployee(item.data)"
                        >
                          <icon
                            icon-name="arrow_exchange"
                            class-name="mr-2"
                            size="xs"
                          />
                          <p class="pt-1" style="font-size: 14px">Process</p>
                        </div>
                      </div>
                    </Menu>
                  </span>
                </template>
              </sTable>
              <div
                class="w-full flex flex-col justify-center items-center mt-10"
                v-else
              >
                <icon
                  icon-name="empty_agreement"
                  size="l"
                  style="width: 300px"
                />
                <div class="w-1/2 text-base text-center">
                  There is no Terminated Employees at this time.
                </div>
              </div>
            </div>

            <div class="pt-3" v-else>
              <sTable
                :headers="terminalPayHeaders"
                :items="payrunItems"
                style="width:100%; height:auto"
                class="w-full"
                aria-label="payrun table"
                :loading="loading"
                :has-checkbox="false"
                :has-number="false"
                :pagination-list="metaData"
                @page="handlePage($event)"
                @itemsPerPage="handleItemsPerPage($event)"
                page-sync
              >
                <template v-slot:item="{ item }">
                  <span v-if="item.year" style="width: 189px">
                    {{
                      `${$DATEFORMAT(
                        new Date(item.data.year, item.data.month - 1, 1),
                        "LLLL"
                      )} ${item.data.year}`
                    }}
                  </span>
                  <div v-else-if="item.payFrequency">
                    {{ handlePayFrequency(item.data.payFrequency) }}
                  </div>
                  <div class="capitalize" v-else-if="item.payType">
                    {{ item.data.payType }}
                  </div>
                  <div class="" v-else-if="item.paySplitPosition">
                    {{ `${$getOrdinal(item.data.paySplitPosition)} Payment` }}
                  </div>

                  <span v-else-if="item.paidDate">
                    {{ item.data.paidDate }}
                  </span>
                  <span v-else-if="item.paidAt">
                    {{
                      item.data.paidAt
                        ? $DATEFORMAT(new Date(item.data.paidAt), "yyyy-MM-dd")
                        : "-"
                    }}
                  </span>
                  <span v-else-if="item.createdAt">
                    {{
                      item.data.createdAt
                        ? $DATEFORMAT(
                            new Date(item.data.createdAt),
                            "yyyy-MM-dd"
                          )
                        : "-"
                    }}
                  </span>
                  <span v-else-if="item.paymentReferrenceId">
                    {{ item.data.paymentReferrenceId }}
                  </span>
                  <span v-else-if="item.bankName">
                    {{ toTitleCase(item.data.bankName) }}
                  </span>
                  <span v-else-if="item.accountNumber">
                    {{ item.data.accountNumber }}
                  </span>
                  <span v-else-if="item.status">
                    {{ item.data.status }}
                  </span>

                  <span v-else-if="item.type">
                    {{ toTitleCase(item.data.type) }}
                  </span>
                  <span
                    :class="
                      item.data.paymentChannel === 'in-app'
                        ? 'text-mediumSeaGreen'
                        : 'text-darkPurple'
                    "
                    v-else-if="item.paymentChannel"
                  >
                    {{ toTitleCase(item.data.paymentChannel) }}
                  </span>
                  <span v-else-if="item.payDate">
                    {{ item.data.payDate ? item.data.payDate : "-" }}
                  </span>
                  <span v-else-if="item.totalPaye">
                    {{
                      item.data.totalPaye
                        ? convertToCurrency(item.data.totalPaye)
                        : "-"
                    }}
                  </span>
                  <span v-else-if="item.totalPension">
                    {{
                      item.data.totalPension
                        ? convertToCurrency(item.data.totalPension)
                        : "-"
                    }}
                  </span>
                  <span v-else-if="item.grossEarnings">
                    {{
                      item.data.grossEarnings
                        ? convertToCurrency(item.data.grossEarnings)
                        : "-"
                    }}
                  </span>
                  <span v-else-if="item.netEarnings">
                    {{
                      item.data.netEarnings
                        ? convertToCurrency(item.data.netEarnings)
                        : "-"
                    }}
                  </span>
                  <span v-else-if="item.totalCost" class="grosspay">
                    {{
                      item.data.totalCost
                        ? convertToCurrency(item.data.totalCost)
                        : "-"
                    }}
                  </span>
                  <span v-else-if="item.id">
                    <div
                      class="cursor-pointer"
                      v-if="!item.data.editable && currentTab !== 'Approved'"
                      @click="viewDetails(item.data, currentTab)"
                    >
                      <icon
                        icon-name="icon-eye"
                        class-name="text-blueCrayola"
                        size="xs"
                      />
                    </div>
                    <Menu
                      v-if="!item.data.editable && currentTab === 'Approved'"
                      left
                      top="-150"
                      margin="24"
                      class="my-2 p-0"
                    >
                      <template v-slot:title>
                        <icon icon-name="more_icon" size="xs" />
                      </template>
                      <div style="width: 116px; height: 81px" class="py-3 px-2">
                        <div
                          @click="viewDetails(item.data)"
                          class="flex py-1 px-3 more h-8 cursor-pointer"
                        >
                          <icon
                            icon-name="icon-eye"
                            class-name="text-blueCrayola mr-2"
                            size="xsm"
                          />
                          <p class="pt-1">View</p>
                        </div>
                        <div
                          @click="showCancelComponent(item.data.id)"
                          class="flex py-1 px-3 more h-8 cursor-pointer"
                        >
                          <icon
                            icon-name="close-icon"
                            class-name="text-desire mr-2"
                            size="xsm"
                          />
                          <p class="pt-1">Cancel</p>
                        </div>
                      </div>
                    </Menu>
                  </span>
                </template>
              </sTable>
            </div>
          </div>
        </div>
      </card>
    </div>

    <outstanding-modal
      :open-modal="openModal"
      :modal-type="modalType"
      :user-id="userId"
      @close="handleClose"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Tab from "@scelloo/cloudenly-ui/src/components/tab";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import Card from "@/components/Card";
import Button from "@/components/Button";
import CardFooter from "@/components/CardFooter";
import Menu from "@/components/Menu";

export default {
  name: "Payrun",
  components: {
    Tab,
    Breadcrumb,
    Menu,
    Card,
    Button,
    CardFooter,
    STable,
    OutstandingModal: () => import("../OutstandingModal"),
  },
  data() {
    return {
      openModal: false,
      currentTab: this.$route.query.currentTab || "Terminated Employees",
      showError: false,
      annualGrossTotal: 100,
      showCancelModal: false,
      payRunDataId: "",
      modalType: "",
      userId: "",
      metaData: {},
      tabs: [
        "Terminated Employees",
        "Drafts",
        "Under Review",
        "Approved",
        "Disapproved",
        "Paid",
      ],
      currentText: "Created At",
      payruns: {},
      loading: true,
      selectedEmployees: [],
      loadingBackPay: false,
      payrunItems: [],
      status: "",
      state: "drafts",
      optionSelected: "Regular Pay Run",
      breadcrumbs: [
        { disabled: false, text: "Payroll", href: "Payroll", id: "Payroll" },
        { disabled: false, text: "Payrun", href: "Payrun", id: "Payrun" },
        {
          disabled: false,
          text: "Off-Cycle Pay",
          href: "Off Cycle",
          id: "Off-Cycle",
        },
        {
          disabled: false,
          text: "Terminal Pay",
          href: "TerminalPay",
          id: "TerminalPay",
        },
      ],
      itemsPerPage: null,
      numberOfPage: null,
      payload: {
        employeeIds: [],
      },
    };
  },

  watch: {
    currentTab(value) {
      this.checkCurrentTab(value);
      this.$router.push({ query: { currentTab: value } });
    },
  },

  computed: {
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
    terminalPayHeaders() {
      let headerArry = [];
      if (this.currentTab === "Terminated Employees") {
        headerArry = [
          { title: "", value: "photo", width: 5 },
          { title: "Employee Name", value: "employeeName" },
          { title: "Gender", value: "gender" },
          { title: "Phone", value: "phone" },
          { title: "Designation", value: "designation" },
          { title: "Job Level", value: "jobLevel" },
          { title: "Manager", value: "manager" },
          { title: "Effective Terminal Date", value: "terminalDate" },
          { title: "", value: "id", image: true },
        ];
      } else if (this.currentTab === "Drafts") {
        headerArry = [
          { title: "Pay Run Type", value: "payType" },
          { title: "Due Date", value: "paidAt" },
          { title: "Gross Earnings", value: "grossEarnings" },
          { title: "Paye", value: "totalPaye" },
          { title: "Pension", value: "totalPension" },
          { title: "Net Earnings", value: "netEarnings" },
          { title: "Total Cost", value: "totalCost" },
          { title: "", value: "id", image: true },
        ];
      } else {
        headerArry = [
          { title: "Pay Run Cycle", value: "year" },
          { title: "Pay type", value: "payType" },
          { title: "Pay frequency", value: "payFrequency" },
          { title: "Pay SCHEDULE", value: "paySplitPosition" },
          {
            title: this.currentTab === "Paid" ? "Pay Date" : "Created At",
            value: this.currentTab === "Paid" ? "paidAt" : "createdAt",
          },
          { title: "Gross Earnings", value: "grossEarnings" },
          { title: "Net Earnings", value: "netEarnings" },
          { title: "Total Cost", value: "totalCost" },
          { title: "", value: "id" },
        ];
        if (this.currentTab === "Paid") {
          headerArry.splice(6, 0, {
            title: "Channel",
            value: "paymentChannel",
          });
        }
      }
      return headerArry;
    },
  },

  methods: {
    viewDetails(data, tab) {
      if (tab !== "Terminated Employees") {
        this.$router.push({
          name: "viewDraftTerminalPayrun",
          params: {
            id: data.id
          },
        });
      }
    },

    processEmployees(value) {
      this.selectedEmployees = value;
    },

    processOneEmployee(value) {
      this.selectedEmployees.push(value);
      this.processPayRun();
    },

    processPayRun() {
      this.selectedEmployees.forEach((employee) => {
        this.payrunItems.forEach((item) => {
          if (item.id === employee.id) {
            this.payload.employeeIds.push(employee.id);
          }
        });
      });
      this.$_processTerminatedEmployees(this.payload)
        .then((response) => {
          this.$router.push({
            name: "viewTerminalEmployees",
            params: { id: response.data.processedEmployees.id },
          });
        })
        .catch(() => {
          this.$toasted.error("Could not Process Selected Employees", {
            duration: 5000,
          });
        });
    },

    handleClose() {
      this.openModal = false;
      this.modalType = "";
    },

    buttonOptionSelected(option) {
      switch (option) {
        case "Regular Pay Run":
          this.$router.push({ name: "Pay Runs" });
          break;

        case "Back Pay":
          this.$router.push({ name: "BackPay" });
          break;

        default:
          break;
      }
    },

    checkCurrentTab(value) {
      switch (value) {
        case "Terminated Employees":
          this.terminatedEmployees();
          break;

        case "Drafts":
          this.payrunItems = [];
          this.loading = true;
          this.$_getPayrun("draft", "&page=1&perPage=50", "terminalPay").then(
            (result) => {
              result.data.payruns.forEach((payrun) => {
                this.payrunItems.push({
                  id: payrun.id,
                  payType: payrun.payType,
                  paidAt: payrun.payDate,
                  grossEarnings: payrun.grossEarnings,
                  totalPaye: payrun.paye,
                  totalPension: payrun.totalPension,
                  netEarnings: payrun.netEarnings,
                  totalCost: payrun.totalCost,
                });
              });
              this.metaData = result.data.meta;
              this.loading = false;
            }
          );
          break;

        case "Under Review":
          this.loading = true;
          this.$_getPayrun("review", "&page=1&perPage=50", "terminalPay").then((result) => {
            this.payrunItems = result.data.payruns;
            this.metaData = result.data.meta;
            this.loading = false;
          });
          break;

        case "Approved":
          this.loading = true;
          this.$_getPayrun("approved", "&page=1&perPage=50", "terminalPay").then((result) => {
            this.payrunItems = result.data.payruns;
            this.metaData = result.data.meta;
            this.loading = false;
          });
          break;

        case "Disapproved":
          this.loading = true;
          this.$_getPayrun("disapproved", "&page=1&perPage=50", "terminalPay").then(
            (result) => {
              this.payrunItems = result.data.payruns;
              this.metaData = result.data.meta;
              this.loading = false;
            }
          );
          break;

        case "Paid":
          this.loading = true;
          this.$_getPayrun("paid", "&page=1&perPage=50", "terminalPay").then((result) => {
            this.payrunItems = result.data.payruns;
            this.metaData = result.data.meta;
            this.loading = false;
          });
          break;

        default:
          break;
      }
    },

    listUnprocessedTerminalPay() {
      this.payrunItems = [];
      this.loading = true;
      this.$_getListTerminatedPayrun()
        .then((response) => {
          response.data.unprocessedTerminalPays.forEach((terminalPay) => {
            this.payrunItems.push({
              payType: "Terminal Pay",
              payDate: terminalPay.year,
              month: terminalPay.month,
              employeeCount: "",
              grossPay: terminalPay.grossEarnings,
              totalPaye: terminalPay.paye,
              totalPension: terminalPay.totalPension,
              totalNhf: "",
              totalNetPay: terminalPay.netEarnings,
              totalCost: terminalPay.totalCost,
              orgId: terminalPay.orgId,
            });
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    handleClick(type, id) {
      this.modalType = type;
      this.userId = id;
      this.openModal = true;
    },

    terminatedEmployees() {
      this.payrunItems = [];
      this.loading = true;
      this.$_getTerminalPayEmployees()
        .then((response) => {
          response.data.terminatedEmployees.forEach((employees) => {
            this.payrunItems.push({
              id: employees.userId,
              photo: null,
              employeeName: `${employees.fname} ${employees.lname}`,
              designation: employees.designation
                ? employees.designation
                : "---",
              gender: employees.gender,
              phone: employees.phone,
              jobLevel: employees.level ? employees.designation : "---",
              manager: employees.reporting
                ? `${employees.reporting.fname} ${employees.reporting.lname}`
                : "---",
              terminalDate: employees.effectiveDate,
            });
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    if (this.currentTab === "Terminated Employees") {
      this.terminatedEmployees();
    } else if (this.currentTab === "Drafts") {
      this.$_getPayrun("draft", "&page=1&perPage=50", "terminalPay").then(
        (result) => {
          result.data.payruns.forEach((payrun) => {
            this.payrunItems.push({
              id: payrun.id,
              payType: payrun.payType,
              paidAt: payrun.payDate,
              grossEarnings: payrun.grossEarnings,
              totalPaye: payrun.paye,
              totalPension: payrun.totalPension,
              netEarnings: payrun.netEarnings,
              totalCost: payrun.totalCost,
              month: payrun.month,
              year: payrun.year
            });
          });
          this.metaData = result.data.meta;
          this.loading = false;
        }
      );
    } else if (this.currentTab === "Under Review") {
      this.$_getPayrun("review", "&page=1&perPage=50", "terminalPay").then(
        (result) => {
          this.payrunItems = result.data.payruns;
          this.metaData = result.data.meta;
          this.loading = false;
        }
      );
    } else if (this.currentTab === "Approved") {
      this.$_getPayrun("approved", "&page=1&perPage=50", "terminalPay").then(
        (result) => {
          this.payrunItems = result.data.payruns;
          this.metaData = result.data.meta;
          this.loading = false;
        }
      );
    } else if (this.currentTab === "Disapproved") {
      this.$_getPayrun("disapproved", "&page=1&perPage=50", "terminalPay").then(
        (result) => {
          this.payrunItems = result.data.payruns;
          this.metaData = result.data.meta;
          this.loading = false;
        }
      );
    } else if (this.currentTab === "Paid") {
      this.$_getPayrun("paid", "&page=1&perPage=50", "terminalPay").then(
        (result) => {
          this.payrunItems = result.data.payruns;
          this.metaData = result.data.meta;
          this.loading = false;
        }
      );
    }
  },
};
</script>
